import React from "react";
import { Pagination } from "@nextui-org/react";

type TablePaginationProps = {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const TablePagination: React.FC<TablePaginationProps> = ({
  page,
  totalPages,
  onPageChange,
}) => {
  if (totalPages < 2) {
    return <></>;
  }

  return (
    <div className="flex w-full justify-start">
      <Pagination
        isCompact
        showControls
        showShadow
        size="sm"
        color="secondary"
        page={page}
        total={totalPages}
        onChange={onPageChange}
      />
    </div>
  );
};

export default TablePagination;

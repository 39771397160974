import CheronRight from "assets/svgs/cheronright.svg";
import { links } from "utils/helper/links";
import { LiaTimesSolid } from "react-icons/lia";
import AddButton from "../main/submit-button.component";
import { Link } from "react-router-dom";

function PrivacyPageHeader({ loading = false }) {
  return (
    <div className="block justify-between space-y-3 page-header sm:flex">
      <div>
        <h3 className="text-dark-base hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
          Settings
        </h3>
        <ol className="flex items-center whitespace-nowrap min-w-0 mt-2">
          <li className="text-sm">
            <Link
              className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate"
              to={links.dashboard}>
              Dashboard
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li className="text-sm">
            <span className="flex items-center text-gray-500 font-semibold hover:text-primary dark:text-gray-200 truncate">
              Settings
              <CheronRight className="flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </span>
          </li>
          <li
            className="text-sm hover:text-primary dark:text-white/70 "
            aria-current="page">
            Privacy Policy
          </li>
        </ol>
      </div>
      <div className="flex items-center whitespace-nowrap min-w-0 gap-2">
        <Link
          to={links["product.productlist"]}
          className="btn btn-outline">
          <LiaTimesSolid size={20} /> Cancel
        </Link>
        <AddButton
          text="Save Changes"
          loading={loading}
        />
      </div>
    </div>
  );
}

export default PrivacyPageHeader;

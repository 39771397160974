import DashboardLayout from "components/layouts/main-layout";
import Protected from "components/layouts/protected";
import TermsForm from "components/terms";

function TermsPage() {
  return (
    <DashboardLayout name="policy">
      <main className="main-content">
        <Protected permissions={[14]}>
          <TermsForm />
        </Protected>
      </main>
    </DashboardLayout>
  );
}

export default TermsPage;

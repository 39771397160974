import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteObject, useLocation } from "react-router-dom";
import { selectCurrentUser, selectIsLoggedIn } from "store/user/user.selector";
import { authLinks } from "utils/helper/links";
import { REDIRECT_URI } from "utils/helper/states";
import NotPermitted from "./forbidden/page";
import { lazy, Suspense } from "react";
import { blogLoader } from "loaders/blog";
import ErrorPage from "components/error/error.component";
import { attributeLoader, brandLoader, categoryLoader } from "loaders/products";
import { roleLoader, staffLoader } from "loaders/staff";
import ComingSoon from "./coming-soon";
import CustomersPage from "./customers";
import { policyLoader } from "loaders/policy";
import Preloader from "components/preloader";
import SupportPage from "./support";
import ViewResponses from "./support/replies";
import { ticketLoader } from "loaders/ticket";
import TermsPage from "./settings/terms";
import PrivacyPage from "./settings/privacy";

const HomePage = lazy(() => import("./home"));
const Attributes = lazy(() => import("./product/attributes"));
const ProfilePage = lazy(() => import("./profile"));
const TopicsPage = lazy(() => import("./blog/topics"));
const BlogPage = lazy(() => import("./blog/list"));
const CreateBlogPage = lazy(() => import("./blog/create"));
const BlogDetailPage = lazy(() => import("./blog/edit/page"));
const EditAttributePage = lazy(() => import("./product/attributes/edit/page"));
const Categories = lazy(() => import("./product/categories"));
const AddCategory = lazy(() => import("./product/categories/add-category"));
const Brands = lazy(() => import("./product/brands"));
const AddBrand = lazy(() => import("./product/brands/add-brand"));
const EditBrand = lazy(() => import("./product/brands/edit"));
const Colors = lazy(() => import("./product/colors/page"));
const EditCategory = lazy(() => import("./product/categories/edit"));
const AddStaffPage = lazy(() => import("./staff/create"));
const StaffPage = lazy(() => import("./staff/list"));
const EditStaffPage = lazy(() => import("./staff/edit"));
const EmailsPage = lazy(() => import("./staff/emails"));
const PermissionsPage = lazy(() => import("./staff/permissions"));
const RolesPage = lazy(() => import("./staff/roles"));
const AddRolePage = lazy(() => import("./staff/roles/create"));
const EditRolePage = lazy(() => import("./staff/roles/edit"));
const VendorsPage = lazy(() => import("./vendors/list/page"));
const Products = lazy(() => import("./product/products"));
const AddProduct = lazy(() => import("./product/products/add-product/page"));
const EditProduct = lazy(() => import("./product/products/edit/page"));
const Coupons = lazy(() => import("./product/coupons"));
const ProductsReport = lazy(() => import("./reports/products"));

// vendor policies

const PoliciesPage = lazy(() => import("./settings/policies"));
const CreatePolicyPage = lazy(() => import("./settings/policies/create"));
const EditPolicyPage = lazy(() => import("./settings/policies/edit"));

function ProtectedRoutes() {
  const isLoggedIn = useSelector(selectIsLoggedIn),
    user = useSelector(selectCurrentUser);
  const location = useLocation();
  if (isLoggedIn && user != null) {
    return user.type !== "admin" && user.type !== "staff" ? (
      <NotPermitted />
    ) : (
      <Outlet />
    );
  }
  sessionStorage.setItem(REDIRECT_URI, location.pathname);
  return (
    <Navigate
      to={authLinks.login}
      replace
    />
  );
}

export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/customers",
    element: <CustomersPage />,
  },
  {
    path: "/vendors/list",
    element: <VendorsPage />,
  },
  {
    path: "/blog/topics",
    element: <TopicsPage />,
  },
  {
    path: "/blog/list",
    element: <BlogPage />,
  },
  {
    path: "/blog/create",
    element: <CreateBlogPage />,
  },
  {
    path: "/blog/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <BlogDetailPage />
      </Suspense>
    ),
    loader: blogLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product/products",
    element: <Products />,
  },
  {
    path: "/product/products/add-product",
    element: <AddProduct />,
  },
  {
    path: "/product/products/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditProduct />
      </Suspense>
    ),
    loader: attributeLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product/coupons",
    element: <Coupons />,
  },
  {
    path: "/product/colors",
    element: <Colors />,
  },
  // Attribute
  {
    path: "/product/attributes",
    element: <Attributes />,
  },
  {
    path: "/product/attributes/:id",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditAttributePage />
      </Suspense>
    ),
    loader: attributeLoader,
    errorElement: <ErrorPage />,
  },
  // Categories
  {
    path: "/product/categories",
    element: <Categories />,
  },
  {
    path: "/product/categories/add-category",
    element: <AddCategory />,
  },
  {
    path: "/product/categories/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditCategory />
      </Suspense>
    ),
    loader: categoryLoader,
    errorElement: <ErrorPage />,
  },
  // Brands
  {
    path: "/product/brands",
    element: <Brands />,
  },
  {
    path: "/product/brands/add-brand",
    element: <AddBrand />,
  },
  {
    path: "/product/brands/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditBrand />
      </Suspense>
    ),
    loader: brandLoader,
    errorElement: <ErrorPage />,
  },

  // Staffs
  {
    path: "/staff/create",
    element: <AddStaffPage />,
  },
  {
    path: "/staff/list",
    element: <StaffPage />,
  },
  {
    path: "/staff/edit/:id",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditStaffPage />
      </Suspense>
    ),
    loader: staffLoader,
    errorElement: <ErrorPage />,
  },
  // Emails
  {
    path: "/staff/emails",
    element: <EmailsPage />,
  },
  // Permissions
  {
    path: "/staff/permissions",
    element: <PermissionsPage />,
  },
  // Roles
  {
    path: "/staff/roles",
    element: <RolesPage />,
  },
  {
    path: "/staff/create",
    element: <AddRolePage />,
  },
  {
    path: "/staff/roles/:id",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditRolePage />
      </Suspense>
    ),
    loader: roleLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/staff/roles/create",
    element: <AddRolePage />,
  },

  // Policies

  {
    path: "/settings/vendor-policies",
    element: <PoliciesPage />,
  },
  {
    path: "/settings/vendor-policies/create",
    element: <CreatePolicyPage />,
  },
  {
    path: "/settings/vendor-policies/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditPolicyPage />
      </Suspense>
    ),
    loader: policyLoader,
    errorElement: <ErrorPage />,
  },

  {
    path: "/settings/terms-of-service",
    element: <TermsPage />,
  },
  {
    path: "/settings/privacy-policy",
    element: <PrivacyPage />,
  },

  {
    path: "/support/tickets",
    element: <SupportPage />,
  },
  //
  {
    path: "/support/tickets/:code",
    element: (
      <Suspense fallback={<Preloader />}>
        <ViewResponses />
      </Suspense>
    ),
    loader: ticketLoader,
    errorElement: <ErrorPage />,
  },
  // Reports

  {
    path: "/reports/products",
    element: <ProductsReport />,
  },
  {
    path: "/*",
    element: <ComingSoon />,
  },
];
export default ProtectedRoutes;

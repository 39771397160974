import { useDispatch, useSelector } from "react-redux";
import TermsPageHeader from "./header";
import { FormEvent, useEffect, useState } from "react";
import { selectSiteSettings } from "store/data/data.selector";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import Editor from "components/quill/editor";
import { alertMessage } from "components/toolkit/initial-state.component";
import { apiRequest } from "lib/api/apiClient";
import { setSiteSettings } from "store/data/data.slice";

function TermsForm() {
  const [value, setValue] = useState(""),
    [loading, setLoading] = useState(false),
    dispatch = useDispatch(),
    siteSettings = useSelector(selectSiteSettings);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value.length === 0) {
      alertMessage("warn", "Please fill the Terms");
      return;
    }
    setLoading(true);
    try {
      const res = await apiRequest("/api/v1/admin/site-settings", {
        method: "POST",
        body: { key: "terms", value },
      });
      if (res) {
        dispatch(setSiteSettings(res));
        alertMessage("success", "Terms updated successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (siteSettings) {
      setValue(siteSettings.terms);
    }
  }, [siteSettings]);
  return (
    <form onSubmit={handleSubmit}>
      <TermsPageHeader loading={loading} />
      <Card
        className="py-4 lg:px-4 shadow"
        radius="md">
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
          <h4 className="font-bold text-large">Terms</h4>
        </CardHeader>
        <CardBody className="overflow-visible py-2 px-4 space-y-3">
          <Editor
            content={value}
            handleChange={(str: string) => setValue(str)}
          />
        </CardBody>
      </Card>
    </form>
  );
}

export default TermsForm;

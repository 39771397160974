import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  toolbarOptions,
  formats,
  fonts,
  getFontName,
  fontNames,
} from "./toolbar";
import "./font.css";

export default function Editor({
  content,
  handleChange,
}: {
  content?: string;
  handleChange: (str: string) => void;
}) {
  const Font = ReactQuill.Quill.import("attributors/class/font") as any;
  // const Font = ReactQuill.Quill.import("formats/font"); // <<<< ReactQuill exports it
  Font.whitelist = fontNames; // allow ONLY these fonts and the default
  ReactQuill.Quill.register(Font, true);
  return (
    <ReactQuill
      theme="snow"
      value={content}
      onChange={handleChange}
      className="h-full"
      modules={{
        toolbar: toolbarOptions,
      }}
    />
  );
}
